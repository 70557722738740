@import url("https://fonts.googleapis.com/css?family=Orbitron");

//---DEFAULT---//
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
//---END OF DEFAULT---//

//---WRAP---//
.body{  
  display: flex;
  text-align: center;
  min-width: 1400px;
  padding: 0px;
  margin: 0px;
  
}

.wrap{
  width:100%;
  min-width: 1400px;
  -ms-user-select: none; 
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  
}
//--END OF WRAP--//

//---HEADER---//
.header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: 80px;
  width: 100%;
  min-width: 1400px;
  z-index: 10;
  background-color: rgba(255,255,255,1);
}

.main-nav  {
  display:flex;
  flex-wrap: wrap;
  list-style-type: none;
  min-width: 1400px;
  max-width: 1400px;
  &__logo{
    height: 80px;
    width: 280px;
    display: flex;
    align-items: center;
  }
  &__item{
    height: 80px;
    width: 150px;
  }
  &__item2{
    height: 80px;
    width: 250px;
  }
}
.main-nav__logo:hover{
  cursor: pointer;
}

.main-nav__img{
  width: 240px;
}

.main-nav__submenu {
  display:block;
  position: absolute;
  list-style: none;
  width: 160px;
  padding-left: 0;
  margin: 0;
  background-color: white;
}
    
.main-nav__submenu-item {
  height: 40px;
  &:hover {
    background: rgba(#000, 0.1);
  }
}

.sub-nav{
  color: black;
  text-decoration:none;
  font-size:18px;
  line-height: 30px;
  font-weight: bold;
  font-family: "Orbitron";
  transition: 0.2s;
}

.sub-nav:hover{
  line-height: 30px;
  font-size: 20px;
}

//---END OF HEADER---//

//---SIDEBAR---//
*{
  font-family: "Orbitron";
  padding:0;
}

.btn{
  z-index: 20;
  position: fixed;
  top:15px;
  left:25px;
  height: 45px;
  width: 45px;
  text-align: center;
  background-color: #1b1b1b;
  border-radius: 3px;
  cursor:pointer;
  transition: left 0.4s ease;
}

.btn.click{
  left: 260px;
}

.sidebar{
  z-index: 20;
  color: white;
  position:fixed;
  width:250px;
  height:100%;
  left:-250px;
  background-color: #1b1b1b;
  transition: left 0.4s ease;
}

.sidebar.show{
  left: 0px;
}

.sidebar-text{
  background: #1e1e1e;
  color: white;
  font-size: 25px;
  text-align: center;
  font-weight: bold;
  line-height: 40px;
  height: 40px;
  letter-spacing: 1px;
  text-decoration: none;
}
.sidebar-text:hover{
  cursor: pointer;
}
nav ul{
  background-color: #1b1b1b;
  height:100%;
  width:100%;
}

nav ul li{
  text-align: left;
  line-height: 60px;
  border-bottom: 1px solid rgba(255,255,255,0.1);
}

nav ul li a{
  padding-left:38px;
  color: white;
  text-decoration: none;
  font-size:18px;
  font-weight: bold;
  display: block;
  width: 100%;
  box-sizing: border-box;
  border-right: 5px solid transparent;
}

nav ul li a:hover{
  background: rgba(255,255,255,1);
  color: #1b1b1b;
  border-right-color: #1b1b1b;
}

nav ul ul{
  position: static;
  display:none;
}

nav ul ul li{
  line-height:42px;
  border-bottom:none;
}

nav ul ul li a{
  font-size: 17px;
  color: #e6e6e6;
  padding-left:42px;
}
//---END OF SIDEBAR---//

//---PRODUCT HEADER---//
.product-nav  {
  display:flex;
  flex-wrap: wrap;
  margin-top: 7px;
  min-width: 1200px;
  max-width: 1200px;
  max-height: 100px;
  &__item-x{
    //border-right: solid white;
    height: 35px;
    width: 230px;
  }
  &__item-v{
    //border-right: solid white;
    height: 35px;
    width: 230px;
  }
}
//---END OF PRODUCT HEADER---//

//---FOOTER---//
.footer{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background-color: rgba(60,60,60,1);
  min-width: 1200px;
  width: 100%;
  font-family:"Orbitron";
}

.block{
  margin-top: 20px;
  padding: 0 5px 0 5px;
  background-color: rgb(26, 18, 18);
  color:rgba(255,255,255,1);
  border-radius: 7px;
  height: 30px;
  line-height: 30px;
  font-weight: bold;
}

.text{
  margin: 20px 50px 0 10px;
  font-weight: bold;
  color:rgba(255,255,255,1);
  height: 30px;
  line-height: 30px;
  font-weight: bold;
}
.copyright{
  margin: 20px 50px 50px 10px;
  font-weight: bold;
  color:rgba(255,255,255,1);
  width: 100%;
  height: 30px;
  line-height: 50px;
  font-weight: bold;
}
//---END OF FOOTER---//

//---CONTENTS---//
.section{
  -ms-user-select: none; 
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  font-family:"Orbitron";
  &__title{
    width: 100%;
    height: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(../src/assets/images/main_img/m2.jpg) no-repeat;
    background-position:center center;
    background-size: cover;
    box-shadow: inset 2000px 0 0 0 rgba(0,0,0,0.2);
    &__info{
      display: flex;
      flex-direction: column;
      justify-content:left;
      min-width: 1200px;
      max-width: 1200px;
    }
    &__label{
      text-align: left;
      transition:0.5s;
      transform-origin: left;
      transform: scaleX(1);
      margin-left: 50px;
    }
    &__info{
      text-align: left;
      margin-left: 80px;
    }
  }
  &__product{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 600px;
    background-color:  rgba(255,255,255,1);
  }
  &__experience{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top:120px;
    margin-bottom: 80px;
    background-color:  rgba(255,255,255,1);
  }
  &__seperator{
    width: 100%;
  }
  
  &__paper{
    height: 500px;
    display: flex;
    background-color:  rgba(255,255,255,1);
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  &__area{
    height: 700px;
    display: flex;
    background-color:  rgba(255,255,255,1);
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    &__img{
      height: 80%;
    }
  }
  &__empty{
    height:50px;
  }
  &__product-header{
    z-index: 0;
    margin-top:80px;
    height: 180px;
    display: flex;
    justify-content: center;
    background: url(../src/assets/images/main_img/m2.jpg) no-repeat;
    background-position:center;
    background-size: cover;
    box-shadow: inset 2000px 0 0 0 rgba(0,0,0,0.5);
  }
  &__product-info{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__history{
    max-width: 2000px;
    margin: 0 auto;
    text-align: center;
    margin-top:120px;
    margin-bottom: 70px;
    background-color:  rgba(255,255,255,1);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__history__row{
    width:80%;
    background-color: rgba(255,255,255,1);
    display: flex;

    flex-direction: row;
  }
  &__contact{
    margin-top:120px;
    margin-bottom: 80px;
    background-color:  rgba(255,255,255,1);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
//---END OF SECTION---//

//---HISTORY---//
.history-empty{
  height: 80px;
}

.history-row{
  min-width: 30%;
  max-width: 1000px;
  margin-bottom: 30px;
  border-bottom: 1px solid #404040;
  overflow: hidden;
  max-height: 70px;
  transition: max-height 0.2s;
}

.history-row-center{
  min-width: 30%;
  max-width: 1000px;
  margin-bottom: 30px;
  border-bottom: 1px solid #404040;
  overflow: hidden;
  max-height: 70px;
  transition: max-height 0.2s;
  padding-left: 20px;
}

.history-row-center_NotPadding{
  min-width: 30%;
  max-width: 1000px;
  margin-bottom: 30px;
  border-bottom: 1px solid #404040;
  overflow: hidden;
  max-height: 70px;
  transition: max-height 0.2s;
}

.history-years{
  font-size: 40px;
  font-weight: bold;
  color:rgba(0,80,157,1);
  float: left;
  width: 100%;
  display: flex;
  margin-bottom: 30px;
}

.history-font-transparent{
  font-size: 40px;
  font-weight: bold;
  float: left;
  width: 100%;
  display: flex;
  margin-bottom: 30px;
  color:transparent;
}

.history-items{
  float: left;
  width: 100%;
}

.history-button{
  font-size: 18px;
  font-weight: bold;
  color:rgba(0,80,157,1);
  float: left;
  height: 81px;
  width: 100%;
  text-align: right;
}

.history-item{
  text-align: left;
  font-size: 15px;
  height: 50px;
}
//---END OF HISTROY---//

//---PRODUCT---//
.product-area{
  z-index:5;
  margin-top: -60px;
  margin-bottom: 80px;
  width:65%;
  min-width: 1100px;
  max-width: 1200px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFF;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  transition: 0.5s;
}

.top-area{
  width: 100%;
  height: 25%;
  display: flex;
  justify-content: center;
}
.middle-area{
  width: 90%;
  height: 35%;
}
.bottom-area{
  width: 90%;
  height: 75%;
}

.left-area{
  width: 45%;
  height: 95%;
}
.right-area{
  width: 45%;  
  height: 95%;
}


.grid{
  width: 1200px;
  height: 340px;
  display:flex;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: space-around;
  font-family:"Orbitron";
  &__item{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 300px;
    height: 270px;
    padding: 30px;
    color: rgba(0,80,157,1);
    border: 2px solid rgba(0,80,157,1);
    border-radius: 3px;
    &__title{
      height: 90px;
      font-size: 26px;
      font-weight: bold;
    }
    &__text{
      height: 130px;
      font-size: 18px;
    }
    &__button{
      text-align: center;
      height: 50px;
    }
  }
}

.button{
  position: relative;
  color: rgba(255,255,255,1);
  height: 40px;
  width: 140px;
  line-height: 40px;
  background-color: rgba(0,80,157,1);
  border: 2px solid rgba(0,80,157,1);
  border-radius: 3px;
  cursor: pointer;

  transition: ease-out 0.2s;
}
.button:hover{
  background-color: rgba(0,80,157,0);
  color: rgba(0,80,157,1);
}


.f1{
  font-size: 52px;
  font-weight: bold;
  color: rgba(255,255,255,1);
}
.f2{
  font-size: 20px;
  font-weight: bold;
  color: rgba(255,255,255,1);
}

.slider{
  width: 70%;
  min-width: 1200px;
  max-width: 1200px;
  height: 250px;
  overflow: hidden;
}

.card-wrapper{
  position:relative;
  width: 100%;
  height: 100%;
  &:active,&:hover,&:focus{
    outline: none;
    border:none;
  }
}

.card{
  width:170px;
  overflow: hidden;
  cursor:pointer;
  .card-image{
    width:100%;
    height: 100%;
    z-index:2;
    transition: 0.5s;
    img{
      width: 100%;
      height: 100%;
      object-fit:cover;
    }
  }
}

.p-empty{
  height: 30px;
}
.p-border{
  width: 100%;
  border-top: 1px solid #808080;
  margin-top: 30px;
  margin-bottom: 30px;
}
.p3{
  font-weight: bold;
  text-align: left;
  font-size: 60px;
  font-family:"Orbitron";
}
.p4{
  font-weight: bold;
  text-align: left;
  font-size: 24px;
  color: #404040;
  font-family:"Orbitron";
}
.p5{
  text-align: left;
  margin-left: 10px;
  font-size: 20px;
  color: #404040;
  font-family:"Orbitron";
}
.p6{
  text-align: left;
  font-size: 18px;
  color: #404040;
  font-family:"Orbitron";
}

.p-img{
  width: 400px;
  margin: 40px;
  border: 1px solid #909090;
}

.p-img-mini{
  width: 70px;
  height: 70px;
  margin: 5px;
  border: 1px solid #909090;
}

.b-img{
  border: 1px solid #909090;
  border-radius: 3px;
  width: 400px;
  margin: 50px;
  transition: 0.2s;
}

.p-grid{
  z-index: 1;
  width: 100%;
  display:flex;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: space-around;
  font-family:"Orbitron";
  &__item{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-basis: 460px;
    height: 120px;
    padding: 10px;
    color: #404040;
    //border: 1px solid #808080;;
    border-radius: 3px;
    margin-bottom: 30px;
    &__left{
      width: 30%;
      height: 100%;
    }
    &__right{
      width: 70%;
      height: 100%;
      &__title{
        font-size: 18px;
        margin-bottom: 5px;
        color: #404040;
        font-weight: bold;
        text-align: left;
      }
      &__text{
        font-size: 16px;
        color: #404040;
        text-align: left;
      }
    }
  }
}

.c-grid{
  width: 80%;
  min-width: 1200px;
  max-width: 1800px;
  height: auto;
  display:flex;
  flex-wrap: wrap;
  justify-content: space-around;
  font-family:"Orbitron";
  &__item{
    width: 280px;
    height: 120px;
    text-decoration:none;
    margin:10px;
    &__area{
      width: 280px;
      height: 120px;
      color: rgba(0,80,157,1);
      border: 1px solid #dcd7d7;
      border-radius: 3px;
      transition: 0.2s;
      &__top{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 70%;
        border-radius: 3px 3px 0 0;
        background-color: #FFF;
      }
      &__bottom{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 30%;
        border-radius: 0 0 3px 3px;
        background-color: #e6e8ec;
      }
    }
    &__area:hover{
      transform: scale(1.05);
      box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }
  }
}
.c-item-img{
  padding: 10%;
  max-height: 70%;
  max-width: 50%;
}

.c-area{
  display: flex;
  flex-direction: column;
  justify-content:left;
  width:80%;
  max-width: 1100px;
  min-width: 1100px;
}

.c-title{
  font-weight: bold;
  text-align: left;
  font-size: 18px;
  margin-top: 20px;
  color: #202020;
  font-family:"Orbitron";
}

.c-info{
  word-break: keep-all;
  text-align: left;
  font-size: 18px;
  color: #606060;
  font-family:"Orbitron";
}

.contact-img{
  width: 50%;
  min-width: 600px;
  max-width: 600px;
  border: 1px solid #dcd7d7;
}

//---FONT---//

.small-number{
  font-size: 25px;
  margin-left: 5px;
  margin-bottom: 3px;
  display: flex;
  align-items: end;
}

.title{
  height: 48px;
  font-size: 48px;
  color: rgba(0,80,157,1);
  font-weight: bold;
  font-family:"Orbitron";
}

.p1{
  color: black;
  text-decoration:none;
  font-size:20px;
  line-height: 80px;
  font-weight: bold;
  font-family: "Orbitron";
  transition: 0.2s;
}

.p1:hover{
  font-size: 22px;
  line-height: 80px;
}

.p2{
  color: #FFF;
  font-size:20px;
  line-height: 30px;
  font-weight: bold;
  font-family: "Orbitron";
  transition: 0.2s;
}

.p2:hover{
  font-size: 22px;
  line-height: 30px;
  cursor: pointer;
}

.m-body{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.m-header{
  z-index: 10;
  position:fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80px;
  background-color: #ffffff;
}

.m-section{
  width: 100%;
}

.m-logo{
  z-index: 11;
  height: 40px;
}

.m-section__title{
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 100%;
  height: 300px;
  background: url(../src/assets/images/main_img/m2.jpg) no-repeat;
  background-position:center center;
  background-size: cover;
  margin-top: 80px;
  box-shadow: inset 2000px 0 0 0 rgba(0,0,0,0.2);
}

.m-section__title__label{
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  color: #FFF;
  margin-left: 20px;
}

.m-section__title__info{
  text-align: left;
  font-size: 13px;
  color: #FFF;
  margin-left: 20px;
}


.m-section__product{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.m-grid{
  width: 100%;
  display:flex;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: space-around;
  font-family:"Orbitron";
  &__item{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 300px;
    height: 270px;
    padding: 30px;
    margin: 30px;
    color: rgba(0,80,157,1);
    border: 2px solid rgba(0,80,157,1);
    border-radius: 3px;
    &__title{
      height: 90px;
      font-size: 26px;
      font-weight: bold;
    }
    &__text{
      height: 130px;
      font-size: 18px;
    }
    &__button{
      height: 50px;
      text-align: center;
    }
  }
}

.m-section__paper{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.m-slider{
  width: 90%;
  overflow: hidden;
}

.m-section__area{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.m-section__area__img{
  width:90%;
}
.m-footer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: rgba(60,60,60,1);
  width: 100%;
  font-family:"Orbitron";
}

.m-footer-row{
  width: 90%;
  display: flex;
}

@media(max-width:768px){
  .c-grid{
    display:flex;
    flex-wrap: wrap;
    justify-content: space-around;
    height: auto;
    width: 100%;
    min-width: initial;
    max-width: initial;
  }

  .section__history__row{
    width: 80%;
    display:flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: stretch;
  }

  .history-row{
    width: 100%;
    max-width: initial;
    min-width: initial;
  }
  .history-years{
    font-size: 20px;
  }
  .history-item{
    font-size: 14px;
  }
  .block{
    font-size: 11px;
  }
  .text{
    font-size: 11px;
  }
  .copyright{
    font-size: 12px;
  }
  .c-area{
    display: flex;
    flex-direction: column;
    justify-content:left;
    width:90%;
    max-width: initial;
    min-width: initial;
  }
  .contact-img{
    width:90%;
    max-width: initial;
    min-width: initial;
  }
  .product-area{
    margin-top: 60px;
    width:100%;
    max-width: initial;
    min-width: initial;
  }
  .top-area{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
  }
  .left-area{
    width:90%;
  }
  .right-area{
    width: 100%;
  }
  .bottom-area{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    width:100%;
    max-width: initial;
    min-width: initial;
  }
  .b-img{
    width: 90%;
    margin: 0 0 50px 0;
  }
  .section__product-header{
    display: none;
  }
  .title{
    height: 20px;
    font-size: 30px;
    color: rgba(0,80,157,1);
    font-weight: bold;
    font-family:"Orbitron";
  }
}

.experience__img{
  max-width: 1000px;
  width: 100%;
}

#style-3::-webkit-scrollbar {
  width: 7px;
}
#style-3::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
#style-3::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background-color: #999999;
}
#style-3::-webkit-scrollbar-button:start {
  height: 8px;
  background-color: rgba(255, 255, 255, 0);
}
#style-3::-webkit-scrollbar-button:end {
  height: 8px;
  background-color: rgba(255, 255, 255, 0); /* Bottom, Right 방향의 이동버튼 */
}

#f_contact{    
  bottom: 0px;
  padding: 80px 18%;
  background: #1e1e1e;
}
#f_contact_mobile{    
  bottom: 0px;
  padding: 10px;
  background: #1e1e1e;
}
.f_main{
  display: flex;
  flex-wrap: wrap;
}
.f_footer{
  padding: 20px 0;
}
.f_list{
  width: 100%;
  text-align: left;
}
.f_list h4 {
  font-size: 21px;
  color: white;
  margin-bottom: 30px;
  position: relative;
}

.f_list_mobile
{
  font-size: 10px;
  color: white;
  margin-bottom: 30px;
  position: relative;
}
.f_list h4::before{
  content: "";
  position: absolute;
  height: 2px;
  width:60px;
  left: 0;
  bottom:-10px;
  background-color: white;
}
.f_list a{
  color: white;
}


// --- swiper ---//
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-wrapper {
  transition-timing-function: linear;
}




